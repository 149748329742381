// export const GET_PRODUCT_FAMILY_CONFIG = 'app/base-configurations/GET_PRODUCT_FAMILY_CONFIG';
// export const GET_PRODUCT_FAMILY_CONFIG_SUCCESS = 'app/base-configurations/GET_PRODUCT_FAMILY_CONFIG_SUCCESS';
// export const MODIFY_PRODUCT_FAMILY_CONFIG = 'app/base-configurations/MODIFY_PRODUCT_FAMILY_CONFIG';
// export const CREATE_PRODUCT_FAMILY_CONFIG = 'app/base-configurations/CREATE_PRODUCT_FAMILY_CONFIG';

export const GET_CURRENCY_CONFIG = 'app/base-configurations/GET_CURRENCY_CONFIG';
export const GET_CURRENCY_CONFIG_SUCCESS = 'app/base-configurations/GET_CURRENCY_CONFIG_SUCCESS';
export const MODIFY_CURRENCY_CONFIG = 'app/base-configurations/MODIFY_CURRENCY_CONFIG';
export const CREATE_CURRENCY_CONFIG = 'app/base-configurations/CREATE_CURRENCY_CONFIG';

export const GET_GRANTS_CONFIG = 'app/base-configurations/GET_GRANTS_CONFIG';
export const GET_GRANTS_CONFIG_SUCCESS = 'app/base-configurations/GET_GRANTS_CONFIG_SUCCESS';
export const MODIFY_GRANTS_CONFIG = 'app/base-configurations/MODIFY_GRANTS_CONFIG';
export const CREATE_GRANTS_CONFIG = 'app/base-configurations/CREATE_GRANTS_CONFIG';

export const GET_ACCUMULATORS_CONFIG = 'app/base-configurations/GET_ACCUMULATORS_CONFIG';
export const GET_ACCUMULATORS_CONFIG_SUCCESS = 'app/base-configurations/GET_ACCUMULATORS_CONFIG_SUCCESS';
export const MODIFY_ACCUMULATORS_CONFIG = 'app/base-configurations/MODIFY_ACCUMULATORS_CONFIG';
export const CREATE_ACCUMULATORS_CONFIG = 'app/base-configurations/CREATE_ACCUMULATORS_CONFIG';

// Rate Unit
export const GET_RATE_UNIT = 'app/base-configurations/GET_RATE_UNIT';
export const GET_RATE_UNIT_SUCCESS = 'app/base-configurations/GET_RATE_UNIT_SUCCESS';
export const MODIFY_RATE_UNIT = 'app/base-configurations/MODIFY_RATE_UNIT';
export const CREATE_RATE_UNIT = 'app/base-configurations/CREATE_RATE_UNIT';

// Rate Unit Map
export const GET_RATE_UNIT_MAP = 'app/base-configurations/GET_RATE_UNIT_MAP';
export const GET_RATE_UNIT_MAP_SUCCESS = 'app/base-configurations/GET_RATE_UNIT_MAP_SUCCESS';
export const MODIFY_RATE_UNIT_MAP = 'app/base-configurations/MODIFY_RATE_UNIT_MAP';
export const CREATE_RATE_UNIT_MAP = 'app/base-configurations/CREATE_RATE_UNIT_MAP';

// Time Unit
export const GET_TIME_UNIT_CFG = 'app/base-configurations/GET_TIME_UNIT_CFG';
export const GET_TIME_UNIT_CFG_SUCCESS = 'app/base-configurations/GET_TIME_UNIT_CFG_SUCCESS';
export const MODIFY_TIME_UNIT_CFG = 'app/base-configurations/MODIFY_TIME_UNIT_CFG';
export const CREATE_TIME_UNIT_CFG = 'app/base-configurations/CREATE_TIME_UNIT_CFG';

// Time Custom Record
export const GET_CUSTOM_RECORD_CFG = 'app/base-configurations/GET_CUSTOM_RECORD_CFG';
export const GET_CUSTOM_RECORD_CFG_SUCCESS = 'app/base-configurations/GET_CUSTOM_RECORD_CFG_SUCCESS';
export const MODIFY_CUSTOM_RECORD_CFG = 'app/base-configurations/MODIFY_CUSTOM_RECORD_CFG';
export const CREATE_CUSTOM_RECORD_CFG = 'app/base-configurations/CREATE_CUSTOM_RECORD_CFG';

// Tax config
export const GET_TAX_CODE_CONFIG_BASE = 'app/base-configurations/GET_TAX_CODE_CONFIG_BASE';
export const GET_TAX_CODE_CONFIG_SUCCESS_BASE = 'app/base-configurations/GET_TAX_CODE_CONFIG_SUCCESS_BASE';
export const MODIFY_TAX_CODE_CONFIG_BASE = 'app/base-configurations/MODIFY_TAX_CODE_CONFIG_BASE';
export const CREATE_TAX_CODE_CONFIG_BASE = 'app/base-configurations/CREATE_TAX_CODE_CONFIG_BASE';

export const GET_3RD_PARTY_TAX_CONFIG_BASE = 'app/base-configurations/GET_3RD_PARTY_TAX_CONFIG_BASE';

export const GET_UOM = 'app/base-configurations/GET_UOM';
export const GET_UOM_SUCCESS = 'app/base-configurations/GET_UOM_SUCCESS';
export const CREATE_UOM = 'app/base-configurations/CREATE_UOM';
export const MODIFY_UOM = 'app/base-configurations/MODIFY_UOM';

// Usage Type
export const GET_USAGE_TYPE = 'app/base-configurations/GET_USAGE_TYPE';
export const GET_USAGE_TYPE_SUCCESS = 'app/base-configurations/GET_USAGE_TYPE_SUCCESS';
export const CREATE_USAGE_TYPE = 'app/base-configurations/CREATE_USAGE_TYPE';
export const MODIFY_USAGE_TYPE = 'app/base-configurations/MODIFY_USAGE_TYPE';

export const SEARCH_COUNTRY_CODES = 'app/base-configurations/SEARCH_COUNTRY_CODES';
export const SEARCH_COUNTRY_CODES_SUCCESS = 'app/base-configurations/SEARCH_COUNTRY_CODES_SUCCESS';
export const MODIFY_COUNTRY_CODES = 'app/base-configurations/MODIFY_COUNTRY_CODES';
export const CREATE_COUNTRY_CODES = 'app/base-configurations/CREATE_COUNTRY_CODES';

export const SEARCH_PAC_PRODUCT_CODE = 'app/base-configurations/SEARCH_PAC_PRODUCT_CODE';
export const SEARCH_PAC_PRODUCT_CODE_SUCCESS = 'app/base-configurations/SEARCH_PAC_PRODUCT_CODE_SUCCESS';

export const SEARCH_PREPAID_SYSTEM_OFFERS = 'app/base-configurations/SEARCH_PREPAID_SYSTEM_OFFERS';
export const SEARCH_PREPAID_SYSTEM_OFFERS_SUCCESS = 'app/base-configurations/SEARCH_PREPAID_SYSTEM_OFFERS_SUCCESS';
export const CREATE_PREPAID_SYSTEM_OFFERS = 'app/base-configurations/CREATE_PREPAID_SYSTEM_OFFERS';
export const MODIFY_PREPAID_SYSTEM_OFFERS = 'app/base-configurations/MODIFY_PREPAID_SYSTEM_OFFERS';
export const SEARCH_ZONE_UNIT = 'app/base-configurations/SEARCH_ZONE_UNIT';
export const GET_ZONE_UNIT_BY_ID = 'app/base-configurations/GET_ZONE_UNIT_BY_ID';
export const CREATE_ZONE_UNIT = 'app/base-configurations/CREATE_ZONE_UNIT';
export const MODIFY_ZONE_UNIT = 'app/base-configurations/MODIFY_ZONE_UNIT';
export const CREATE_REGULATORY_PRODUCT_CODES = 'app/base-configurations/CREATE_REGULATORY_PRODUCT_CODES';
export const MODIFY_REGULATORY_PRODUCT_CODES = 'app/base-configurations/MODIFY_REGULATORY_PRODUCT_CODES';

export const SEARCH_CONFIG_MULTI_RATING = 'app/base-configurations/SEARCH_CONFIG_MULTI_RATING';
export const MODIFY_CONFIG_MULTI_RATING = 'app/base-configurations/MODIFY_CONFIG_MULTI_RATING';
export const CREATE_CONFIG_MULTI_RATING = 'app/base-configurations/CREATE_CONFIG_MULTI_RATING';
