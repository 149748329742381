import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataTable, GenericInput, TitleFrom, CollapsibleTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { searchConfigMultiRating, createConfigMultiRating, modifyConfigMultiRating } from '../actions';
import RouteNames from '../../App/RouteNames';
import { validate, supportRemoveIndexWithSize, compareArrayIndexValueChange } from '../../../utils/utils';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import {
  checkPermissionGetConfigUsageType,
  checkPermissionCreateConfigUsageType,
  checkPermissionModifyConfigUsageType,
} from '../CheckPermission';
import { getConfigUsageTypeConfig } from '../../App/actions';

const MultiRateUsageType = ({
  searchConfigMultiRating,
  createConfigMultiRating,
  modifyConfigMultiRating,
  permissionPricing,
  getConfigUsageTypeConfig,
}) => {
  const formRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [wasValidated, setValidate] = useState(false);
  const [activeTabTable, setActiveTabTable] = useState(-1);
  const [defaultData, setDefaultData] = useState(null);
  const [usageTypeOptions, setUsageTypeOptions] = useState({});

  let modeGetConfigUsageType = 0;
  let modeCreateConfigUsageType = 0;
  let modeModifyConfigUsageType = 0;

  if (permissionPricing && permissionPricing.pricingModulePermissions) {
    const listPermission = permissionPricing.pricingModulePermissions;
    modeCreateConfigUsageType = checkPermissionCreateConfigUsageType({ listPermission });
    modeGetConfigUsageType = checkPermissionGetConfigUsageType({ listPermission });
    modeModifyConfigUsageType = checkPermissionModifyConfigUsageType({ listPermission });
  }

  const { t } = useTranslation('common');

  const onChangeData = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = () => {
    doGetConfigUsageTypeConfig();
    try {
      let newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (newData)
        newData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        serviceType: null,
        subscriptionCategory: null,
        isNew: true,
      };
      newData = [payload, ...newData];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleTableTab = (indexItem, newData) => {
    doGetConfigUsageTypeConfig();
    if (activeTabTable === indexItem) return setActiveTabTable(-1);
    if (activeTabTable !== indexItem) {
      setDefaultData(cloneDeep(newData ? newData[indexItem] : data[indexItem]));
      return setActiveTabTable(indexItem);
    }
    return setActiveTabTable(-1);
  };

  const doSearchConfigMultiRating = useCallback(
    item => {
      if (modeGetConfigUsageType) {
        setLoading(true);
        setValidate(false);
        searchConfigMultiRating('', ({ success, data }) => {
          setLoading(false);
          if (success && data) {
            setData(data);
            if (item?.id) {
              const indexItem = data.findIndex(dt => dt.id === item.id);
              onToggleTableTab(indexItem, data);
            }
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchConfigMultiRating, modeGetConfigUsageType]
  );

  const onSave = ({ item }) => {
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    const payload = cloneDeep(item);
    const { serviceType, id, subscriptionCategory, configMultiRatingUsageTypes, isNew } = payload;
    if (!isNew) {
      return modifyConfigMultiRating(
        {
          id: id || null,
          serviceType: serviceType || null,
          subscriptionCategory: subscriptionCategory || null,
          configMultiRatingUsageTypes: compareArrayIndexValueChange({
            defaultData: defaultData ? defaultData.configMultiRatingUsageTypes : null,
            newData: supportRemoveIndexWithSize({ data: configMultiRatingUsageTypes }),
          }),
        },
        ({ success, data }) => {
          if (success) {
            setDefaultData(null);
            doSearchConfigMultiRating(data);
          }
        }
      );
    }
    return createConfigMultiRating(
      {
        id: id || null,
        serviceType: serviceType || null,
        subscriptionCategory: subscriptionCategory || null,
        configMultiRatingUsageTypes: configMultiRatingUsageTypes
          ? configMultiRatingUsageTypes.map(val => {
              const { index, isNew, ...rest } = val;
              return rest;
            })
          : null,
      },
      ({ success, data }) => {
        if (success) {
          setDefaultData(null);
          doSearchConfigMultiRating(data);
        }
      }
    );
  };

  const addNewSubItem = () => {
    try {
      const newData = cloneDeep(data);
      if (!newData[activeTabTable].configMultiRatingUsageTypes)
        newData[activeTabTable].configMultiRatingUsageTypes = [];
      const payload = {
        usageType: null,
        isNew: true,
      };
      newData[activeTabTable].configMultiRatingUsageTypes = [
        payload,
        ...newData[activeTabTable].configMultiRatingUsageTypes,
      ];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeDataSubTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[activeTabTable].configMultiRatingUsageTypes[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onClearData = () => {
    const newData = cloneDeep(data);
    newData[activeTabTable] = defaultData;
    setData(newData);
  };

  const onDeleteItem = ({ index, item }) => {
    const newData = cloneDeep(data);
    try {
      if (item.isNew) {
        newData.splice(index, 1);
        setData(newData);
        setActiveTabTable(-1);
      } else {
        modifyConfigMultiRating(
          {
            id: item.id,
            isDelete: true,
          },
          () => {
            doSearchConfigMultiRating();
            setActiveTabTable(-1);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveItem = ({ index, item }) => {
    const newData = cloneDeep(data);
    try {
      if (item.isNew) {
        newData[activeTabTable].configMultiRatingUsageTypes.splice(index, 1);
      } else {
        newData[activeTabTable].configMultiRatingUsageTypes[index] = {
          index: newData[activeTabTable].configMultiRatingUsageTypes[index].index,
        };
      }
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveConfigColumn =
    modeModifyConfigUsageType === 2
      ? [
          {
            name: 'saveConfig',
            label: 'label.saveConfig',
            render: (colName, item, idx) => (
              <button type="button" className="button x-small" onClick={() => onSave({ item, index: idx })}>
                {t('label.apply')}
              </button>
            ),
          },
          {
            name: 'remove',
            label: 'label.remove',
            render: (colName, item, idx) => {
              return (
                <div className="form-group col-md-12">
                  <button type="button" className="btn-phone" onClick={() => onDeleteItem({ item, index: idx })}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              );
            },
          },
        ]
      : [];

  const tableMultiRateUsageType = [
    {
      name: 'id',
      label: 'label.id',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.id}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="id"
        />
      ),
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.serviceType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="serviceType"
          type="select"
          tOptions="selections:serviceType"
          menuPortalTarget
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'subscriptionCategory',
      label: 'label.subscriptionCategory',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.subscriptionCategory}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="subscriptionCategory"
          type="select"
          tOptions="selections:subscriptionCategory"
          menuPortalTarget
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'additionalData',
      label: '',
      render: (colName, item, idx, indexParent, activeTab) => (
        <button type="button" className="btn-expand-table mr-3" onClick={() => onToggleTableTab(idx)}>
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
    ...saveConfigColumn,
  ];

  const saveConfigSubColumn =
    modeModifyConfigUsageType === 2
      ? [
          {
            name: 'remove',
            label: 'label.remove',
            render: (colName, item, idx) => {
              return (
                <div className="form-group col-md-12">
                  <button type="button" className="btn-phone" onClick={() => onRemoveItem({ item, index: idx })}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              );
            },
          },
        ]
      : [];

  const subTableMultiRateUsageType = [
    {
      name: 'usageType',
      label: 'label.usageType',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, idx) => {
        let listOptions = [];
        if (usageTypeOptions && usageTypeOptions.configUsageTypeService && item && data && data[activeTabTable]) {
          const itemsList = usageTypeOptions.configUsageTypeService.find(
            val => val.serviceType === data[activeTabTable].serviceType && val.status === 'ACTIVE'
          );
          if (itemsList && itemsList.configUsageTypeList) {
            listOptions = itemsList.configUsageTypeList.map(val => ({ label: val.usagetype, value: val.usagetype }));
          }
        }
        return (
          <GenericInput
            value={item.usageType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
            name="usageType"
            type="select"
            options={listOptions}
            menuPortalTarget
            required
            isSupportDefaultValue
          />
        );
      },
    },
    ...saveConfigSubColumn,
  ];

  const doGetConfigUsageTypeConfig = () => {
    if (!usageTypeOptions || !usageTypeOptions.id)
      getConfigUsageTypeConfig('', ({ success, data }) => {
        if (success) {
          setUsageTypeOptions(data);
        }
      });
  };

  useEffect(() => {
    return doSearchConfigMultiRating();
  }, [doSearchConfigMultiRating]);

  if (!modeGetConfigUsageType) return '';

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[{ name: t('baseConfigurationsPage:sidebar.multiRateUsageType') }]}
        isNoDefaultButton
      />
      <br />
      <div className="card card-statistics h-100 mr-3">
        <TitleFrom title={t('baseConfigurationsPage:sidebar.multiRateUsageType')} />
        <br />
        <div>
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateConfigUsageType === 2 && (
              <button type="button" onClick={addNewItem} className="button x-small">
                +
                {t('label.add')}
              </button>
            )}
            <button
              type="button"
              onClick={doSearchConfigMultiRating}
              className="button mr-2 button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <form ref={formRef} noValidate className={`${wasValidated ? 'was-validated' : ''}`}>
            <div className="group-collapsible">
              <CollapsibleTable
                columns={tableMultiRateUsageType}
                data={data || []}
                isSupportRemoveIndex
                indexViewer={activeTabTable}
                isLoading={isLoading}
              >
                <div className="col-md-12 pt-2 pb-3 mb-4">
                  {modeCreateConfigUsageType === 2 && (
                    <button type="button" onClick={addNewSubItem} className="button x-small mb-3 ml-2">
                      +
                      {t('label.add')}
                    </button>
                  )}
                  <div className="col-md-6">
                    <DataTable
                      columns={subTableMultiRateUsageType}
                      data={
                        activeTabTable !== -1 &&
                        data[activeTabTable] &&
                        data[activeTabTable].configMultiRatingUsageTypes
                          ? data[activeTabTable].configMultiRatingUsageTypes
                          : []
                      }
                      isSupportRemoveIndex
                    />
                    <div className="col-md-12 pt-2 pb-3">
                      {modeModifyConfigUsageType === 2 && (
                        <button
                          type="button"
                          onClick={() => onSave({ item: data[activeTabTable] })}
                          className="button button-border x-small float-right mr-4"
                        >
                          {t('label.saveConfig')}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={onClearData}
                        className="button mr-2 mb-2 button-border black x-small float-right"
                      >
                        {t('label.clear')}
                      </button>
                    </div>
                  </div>
                </div>
              </CollapsibleTable>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

MultiRateUsageType.propTypes = {
  searchConfigMultiRating: PropTypes.func.isRequired,
  createConfigMultiRating: PropTypes.func.isRequired,
  modifyConfigMultiRating: PropTypes.func.isRequired,
  getConfigUsageTypeConfig: PropTypes.func.isRequired,
};

MultiRateUsageType.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionPricing: makeGetPermissionsPricingManagement() || {},
});

export default connect(mapStateToProps, {
  searchConfigMultiRating,
  createConfigMultiRating,
  modifyConfigMultiRating,
  getConfigUsageTypeConfig,
})(MultiRateUsageType);
