import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'groupByType',
        'invoiceUnitStatus',
        'accountType',
        'startNumberOfDaysSinceDueDate',
        'endNumberOfDaysSinceDueDate',
        'functionalCurrency',
      ])}`
    : '';
  const getAgeingReport = `getAgeingReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getAgeingReport} {
        totalDue
        total0To15
        total15To30
        total30To60
        total60To90
        total90To120
        total120To150
        total150To180
        total180To270
        total270To290
        total290To365
        total365AndMore
        numberOfDaysSinceDueDate
        accountId
        invoiceId
        organization
        dueDate
        currency
        groupCompany
        salesConsultant
        collectionAgent
        salesFunction
        collectionUnitId
        accountCategory
        collectionUnitStatus
        email
        identity
        identityDocument
        phoneNumber
        street
        extraLine
        landmark
        city
        state
        country
        postalCode
        district
        neighbourhood
        municipality
        locality
        unit
        floor
        longitude
        latitude
        address1
        address2
        address3
      }
    }
  `;
};
