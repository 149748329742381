export const SEARCH_OPERATOR_DISPUTE = 'SEARCH_OPERATOR_DISPUTE';
export const SEARCH_OPERATOR_DISPUTE_SUCCESS = 'SEARCH_OPERATOR_DISPUTE_SUCCESS';
export const GET_OPERATOR_BY_ID = 'GET_OPERATOR_BY_ID';

export const SEARCH_OPERATOR_INVOICE = 'SEARCH_OPERATOR_INVOICE';
export const SEARCH_OPERATOR_INVOICE_SUCCESS = 'SEARCH_OPERATOR_INVOICE_SUCCESS';
export const GET_OPERATOR_INVOICE_BY_ID = 'GET_OPERATOR_INVOICE_BY_ID';

export const SEARCH_OPERATOR_RECORD_LOG = 'SEARCH_OPERATOR_RECORD_LOG';
export const SEARCH_OPERATOR_RECORD_LOG_SUCCESS = 'SEARCH_OPERATOR_RECORD_LOG_SUCCESS';
export const GET_OPERATOR_RECORD_LOG_BY_ID = 'GET_OPERATOR_RECORD_LOG_BY_ID';

export const SEARCH_TENANT_DISPUTE = 'SEARCH_TENANT_DISPUTE';
export const SEARCH_TENANT_DISPUTE_SUCCESS = 'SEARCH_TENANT_DISPUTE_SUCCESS';
export const GET_TENANT_DISPUTE_BY_ID = 'GET_TENANT_DISPUTE_BY_ID';

export const SEARCH_TENANT_RECORD = 'SEARCH_TENANT_RECORD';
export const SEARCH_TENANT_RECORD_SUCCESS = 'SEARCH_TENANT_RECORD_SUCCESS';
export const GET_TENANT_RECORD_BY_ID = 'GET_TENANT_RECORD_BY_ID';

export const OPERATOR_INVOICE_RECON = 'OPERATOR_INVOICE_RECON';
export const OPERATOR_DISPUTE_RECON = 'OPERATOR_DISPUTE_RECON';

export const SEARCH_RERATING_BATCHES = 'SEARCH_RERATING_BATCHES';
export const SEARCH_RERATING_BATCHES_SUCCESS = 'SEARCH_RERATING_BATCHES_SUCCESS';
export const SAVE_RERATING_BATCH = 'SAVE_RERATING_BATCH';
export const GET_RERATING_BY_ID = 'GET_RERATING_BY_ID';
export const MODIFY_RERATING_BATCH = 'MODIFY_RERATING_BATCH';
export const GET_CONFIG_USAGE_PLATFORMS = 'GET_CONFIG_USAGE_PLATFORMS';
export const CREATE_CONFIG_USAGE_PLATFORM = 'CREATE_CONFIG_USAGE_PLATFORM';
export const MODIFY_CONFIG_USAGE_PLATFORM = 'MODIFY_CONFIG_USAGE_PLATFORM';

// Time Custom Record
export const GET_CUSTOM_RECORD_CFG = 'app/usage/GET_CUSTOM_RECORD_CFG';
export const GET_CUSTOM_RECORD_CFG_SUCCESS = 'app/usage/GET_CUSTOM_RECORD_CFG_SUCCESS';
export const MODIFY_CUSTOM_RECORD_CFG = 'app/usage/MODIFY_CUSTOM_RECORD_CFG';
export const CREATE_CUSTOM_RECORD_CFG = 'app/usage/CREATE_CUSTOM_RECORD_CFG';

// File mapping
export const GET_USAGE_FILE_MAPPING_CFG = 'app/usage/GET_USAGE_FILE_MAPPING_CFG';
export const GET_USAGE_FILE_MAPPING_CFG_SUCCESS = 'app/usage/GET_USAGE_FILE_MAPPING_CFG_SUCCESS';
export const CREATE_FILE_MAPPING = 'app/usage/CREATE_FILE_MAPPING';
export const MODIFY_FILE_MAPPING = 'app/usage/MODIFY_FILE_MAPPING';

// Processing Streams
export const GET_USAGE_STREAM_MAPPING_CFG = 'app/base-configurations/GET_USAGE_STREAM_MAPPING_CFG';
export const GET_USAGE_STREAM_MAPPING_CFG_SUCCESS = 'app/base-configurations/GET_USAGE_STREAM_MAPPING_CFG_SUCCESS';
export const CREATE_STREAM_MAPPING = 'app/base-configurations/CREATE_STREAM_MAPPING';
export const MODIFY_STREAM_MAPPING = 'app/base-configurations/MODIFY_STREAM_MAPPING';

// Suspended Failed Batch
export const SEARCH_SUSPENDED_FAILED_BATCH = 'SEARCH_SUSPENDED_FAILED_BATCH';
export const SEARCH_DETAIL_RECORDS = 'SEARCH_DETAIL_RECORDS';
export const UPLOAD_FILE_DETAIL_RECORD = 'UPLOAD_FILE_DETAIL_RECORD';
export const INITIATE_USAGE_REPROCESS = 'INITIATE_USAGE_REPROCESS';
export const INITIATE_RERATING_REPROCESS = 'INITIATE_RERATING_REPROCESS';
export const GET_SUSPENDED_FAILED_BATCH_DETAIL = 'GET_SUSPENDED_FAILED_BATCH_DETAIL';

export const CREATE_AND_PROCESS_USAGE_RECORD = 'CREATE_AND_PROCESS_USAGE_RECORD';

export const GET_ZERO_RATE_USAGE_CONFIG = 'app/base-configurations/GET_ZERO_RATE_USAGE_CONFIG';
export const CREATE_ZERO_RATE_USAGE_CONFIG = 'app/base-configurations/CREATE_ZERO_RATE_USAGE_CONFIG';
export const MODIFY_ZERO_RATE_USAGE_CONFIG = 'app/base-configurations/MODIFY_ZERO_RATE_USAGE_CONFIG';

// Usage Type
export const GET_VOICE_PLAN = 'app/base-configurations/GET_VOICE_PLAN';
export const GET_VOICE_PLAN_SUCCESS = 'app/base-configurations/GET_VOICE_PLAN_SUCCESS';
export const CREATE_VOICE_PLAN = 'app/base-configurations/CREATE_VOICE_PLAN';
export const MODIFY_VOICE_PLAN = 'app/base-configurations/MODIFY_VOICE_PLAN';

export const GET_REGION_MAP_CONFIG = 'app/base-configurations/GET_REGION_MAP_CONFIG';
export const CREATE_REGION_MAP_CONFIG = 'app/base-configurations/CREATE_REGION_MAP_CONFIG';
export const MODIFY_REGION_MAP_CONFIG = 'app/base-configurations/MODIFY_REGION_MAP_CONFIG';

// Trunk Rules
export const GET_TRUNK_RULES = 'app/base-configurations/GET_TRUNK_RULES';
export const GET_TRUNK_RULES_SUCCESS = 'app/base-configurations/GET_TRUNK_RULES_SUCCESS';
export const MODIFY_TRUNK_RULES = 'app/base-configurations/MODIFY_TRUNK_RULES';
export const CREATE_TRUNK_RULES = 'app/base-configurations/CREATE_TRUNK_RULES';
export const SEARCH_TRUNK_RULES_CONFIG_LIST = 'app/base-configurations/SEARCH_TRUNK_RULES_CONFIG_LIST';

export const GET_TENANT_USAGE_INDICATOR = 'app/base-configurations/GET_TENANT_USAGE_INDICATOR';
export const GET_TENANT_TRUNK_TYPE = 'app/base-configurations/GET_TENANT_TRUNK_TYPE';
export const GET_TENANT_TRAFFIC_TYPE = 'app/base-configurations/GET_TENANT_TRAFFIC_TYPE';
export const GET_TENANT_INTER_CONNECT_INDICATOR = 'app/base-configurations/GET_TENANT_INTER_CONNECT_INDICATOR';

export const GET_CONFIG_PNP_PLAN_NUMBER = 'app/base-configurations/GET_CONFIG_PNP_PLAN_NUMBER';
export const GET_CONFIG_PNP_PLAN_NUMBER_SUCCESS = 'app/base-configurations/GET_CONFIG_PNP_PLAN_NUMBER_SUCCESS';

// Operator Map
export const GET_OPERATOR_MAP = 'app/base-configurations/GET_OPERATOR_MAP';
export const GET_OPERATOR_MAP_SUCCESS = 'app/base-configurations/GET_OPERATOR_MAP_SUCCESS';
export const MODIFY_OPERATOR_MAP = 'app/base-configurations/MODIFY_OPERATOR_MAP';
export const CREATE_OPERATOR_MAP = 'app/base-configurations/CREATE_OPERATOR_MAP';
export const SEARCH_CONFIG_OPERATOR_MAP_LIST = 'app/base-configurations/SEARCH_CONFIG_OPERATOR_MAP_LIST';

export const SEARCH_CONFIG_OPERATOR_DATA = 'app/base-configurations/SEARCH_CONFIG_OPERATOR_DATA';
export const SEARCH_CONFIG_OPERATOR_PROVISIONING = 'app/base-configurations/SEARCH_CONFIG_OPERATOR_PROVISIONING';
export const SEARCH_CONFIG_OPERATOR_SMS = 'app/base-configurations/SEARCH_CONFIG_OPERATOR_SMS';
export const SEARCH_CONFIG_OPERATOR_VOICE = 'app/base-configurations/SEARCH_CONFIG_OPERATOR_VOICE';

export const SEARCH_CONFIG_MULTI_RATING = 'app/usage-management/SEARCH_CONFIG_MULTI_RATING';
export const CREATE_CONFIG_MULTI_RATING = 'app/usage-management/CREATE_CONFIG_MULTI_RATING';
export const MODIFY_CONFIG_MULTI_RATING = 'app/usage-management/MODIFY_CONFIG_MULTI_RATING';
export const GET_CONFIG_MULTI_RATING_BY_ID = 'app/usage-management/GET_CONFIG_MULTI_RATING_BY_ID';
