import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getPhcAssetReport = `getPhcAssetReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getPhcAssetReport} {
        accountId
        firstName
        lastName
        invoiceId
        invoicePeriodStart
        invoicePeriodEnd
        lastArAction
        subscriptionEffectiveDate
        priceOfferName
        priceUnitStartDate
        priceUnitEndDate
        invoicePaid
        isIPTVService
        unitPrice
        invoicePending
        invoiceIssued
        serviceType
        priceUnitStatus
        identity
        isTotalAdjustment
        meta
        subscriptionName
        priceOfferId
        withdrawalDate
        cuttingDate
        invoiceStatus
        serviceStatus
        subscriptionStatus
        email
        phoneNumber
      }
    }
  `;
};
