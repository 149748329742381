import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class AccountReportSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:status',
      isClearable: true,
    },
    {
      label: 'label.country',
      name: 'country',
    },
    {
      label: 'label.state',
      name: 'state',
    },
    {
      label: 'label.reason',
      name: 'reason',
      tOptions: 'selections:reason',
      type: 'select',
      isClearable: true,
    },
    { label: 'label.lineOfBusiness', name: 'lineOfBusiness' },
    { label: 'label.identity', name: 'identity' },
  ];

  btnSpan = 'col-md-9';
}

AccountReportSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountReportSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountReportSearchForm);
