import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accountType',
        'status',
        'serviceType',
        'transactionType',
        'billUnitStatus',
      ])}`
    : '';
  const getBilledTransactionSummaryReport = `getBilledTransactionSummaryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBilledTransactionSummaryReport} {
        accountId
        company
        firstName
        lastName
        accountType
        startDate
        endDate
        billUnitId
        serviceType
        priceOfferId
        priceOfferName
        itemId
        itemName
        dueDate
        status
        amount
        billUnitStatus
        invoiceUnitId
        transactionDescription
        billableServiceName
        billableServiceId
        city
        state
        district
        neighbourhood
        postalCode
      }
    }
  `;
};
